import { VueReCaptcha } from 'vue-recaptcha-v3';
import { install } from 'vue3-recaptcha-v2';
import { defineNuxtPlugin, useRuntimeConfig } from '#app/nuxt';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  nuxtApp.vueApp.use(install, {
    sitekey: config.public.googleRecaptchaKey as string,
    cnDomains: false,
  });
});

import revive_payload_client_Ozlj7tPLew from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_trh2txlcogazmeaf3op74hymdq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_f2lgef0JT4 from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_trh2txlcogazmeaf3op74hymdq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_kfroFr0Ylv from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_trh2txlcogazmeaf3op74hymdq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_nc0oP4HPoW from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_trh2txlcogazmeaf3op74hymdq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_HAw8Qvtn9E from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_trh2txlcogazmeaf3op74hymdq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_SUrbTIpr1P from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_trh2txlcogazmeaf3op74hymdq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_wIYt24mf6J from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_trh2txlcogazmeaf3op74hymdq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_LVbtDsytPb from "/app/apps/nuxt-front/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.4_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/nuxt-front/.nuxt/components.plugin.mjs";
import prefetch_client_1TlgLJ034R from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_trh2txlcogazmeaf3op74hymdq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/app/apps/nuxt-front/.nuxt/primevue-plugin.mjs";
import plugin_client_zRzmul4WfS from "/app/apps/nuxt-front/node_modules/.pnpm/@primevue+nuxt-module@4.2.1_@babel+parser@7.26.2_magicast@0.3.5_rollup@4.24.4_vue@3.5.12_type_5ouk2ks7mewspfuwnk65i75qay/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/app/apps/nuxt-front/.nuxt/unocss.mjs";
import maska_UHaKf2z1iQ from "/app/apps/nuxt-front/plugins/maska.ts";
import google_recaptcha_rYK9Kziv68 from "/app/apps/nuxt-front/plugins/google-recaptcha.ts";
export default [
  revive_payload_client_Ozlj7tPLew,
  unhead_f2lgef0JT4,
  router_kfroFr0Ylv,
  payload_client_nc0oP4HPoW,
  navigation_repaint_client_HAw8Qvtn9E,
  check_outdated_build_client_SUrbTIpr1P,
  chunk_reload_client_wIYt24mf6J,
  plugin_vue3_LVbtDsytPb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_1TlgLJ034R,
  primevue_plugin_egKpok8Auk,
  plugin_client_zRzmul4WfS,
  unocss_MzCDxu9LMj,
  maska_UHaKf2z1iQ,
  google_recaptcha_rYK9Kziv68
]